// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1704127448000";
}
// REMIX HMR END

import { Form, Link, useLoaderData } from "@remix-run/react";
import { getUsers, getLastUserID, createUser, deleteUser } from "../data/users.server";
export const meta = () => {
  return [{
    title: "New Remix App"
  }, {
    name: "description",
    content: "Welcome to Remix!"
  }];
};
export async function loader() {
  const users = await getUsers();
  //console.log("users", users);
  return users;
}
export async function action({
  request
}) {
  const formData = await request.formData();
  const {
    _action,
    ...values
  } = await Object.fromEntries(formData);
  //console.log("name", data.name);
  //console.log("email", data.email);
  if (_action === "delete") {
    const id = parseInt(values.id.toString());
    return await deleteUser(id);
  }
  if (_action === "add") {
    const lastUserID = await getLastUserID();
    const new_user = {
      id: lastUserID,
      name: values.name.toString(),
      email: values.email.toString()
    };
    return await createUser(new_user);
  }
  return {
    error: "Invalid action"
  };
}
export default function Index() {
  _s();
  const users = useLoaderData();
  return <div style={{
    fontFamily: "system-ui, sans-serif",
    lineHeight: "1.8"
  }}>
      <h1>Welcome to Remix</h1>
      <ul>
        {users.map(user => <li key={user.id}>
            <Link to={`/users/${user.id}`}>{user.name}</Link>{" "}
            <Form method="post" style={{
          display: "inline-block"
        }}>
              <input type="hidden" name="id" value={user.id} />
              <button type="submit" name="_action" value="delete">
                X
              </button>
            </Form>
          </li>)}

        <li>
          <Form method="post">
            <input name="name" placeholder="name" />{" "}
            <input name="email" placeholder="email" />{" "}
            <button type="submit" name="_action" value="add">
              Submit
            </button>
          </Form>
        </li>
      </ul>
    </div>;
}
_s(Index, "VbJYtru7f/pQ7EJYfMJiZ8Y5lP8=", false, function () {
  return [useLoaderData];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;